@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, 'DM Sans', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



/* Scroll bar styles */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #616C84 #ECEFF7;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1px;
}

*::-webkit-scrollbar-thumb {
  /* background-color: #ECEFF7; */
  width: 4px;
  border-radius: 14px;
  /* border: 3px solid #616C84; */
}


/* input number type  */
/* For webkit browsers like Chrome and Safari */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type=number] {
    -moz-appearance: textfield;
}